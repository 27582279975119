@import "../../assets/styles/variables";

button.ant-btn {
  border: none;
}

.faq {
  .main-layout-content {
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      width: 500px;
      height: 500px;
      position: absolute;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMzZweCIgaGVpZ2h0PSIzMzZweCIgdmlld0JveD0iMCAwIDMzNiAzMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgZmlsbD0iI0ZFRjJGMiIgY3g9IjE2OCIgY3k9IjE2OCIgcj0iMTY4Ij48L2NpcmNsZT4gICAgPC9nPjwvc3ZnPg==");
      background-size: 500px;
      left: 0;
      transform: translateX(-50%);
      top: 0;
      z-index: -1;
    }
    &:after {
      content: "";
      width: 500px;
      height: 500px;
      position: absolute;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMzZweCIgaGVpZ2h0PSIzMzZweCIgdmlld0JveD0iMCAwIDMzNiAzMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgZmlsbD0iI0ZFRjJGMiIgY3g9IjE2OCIgY3k9IjE2OCIgcj0iMTY4Ij48L2NpcmNsZT4gICAgPC9nPjwvc3ZnPg==");
      background-size: 500px;
      right: 0;
      transform: translateX(50%);
      top: 0;
      z-index: -1;
    }
  }

  .ant-layout.banner {
    main {
      padding: 50px 0;
      background-color: #840404;
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        background-image: url("../../assets/svg/circle-overlay-svg.svg");
        background-size: 850px;
        background-position: right 35px bottom 0px;
        background-repeat: no-repeat;
        opacity: 1;
      }

      .search-form {
        .ant-input-search {
          input {
            background: transparent;
            color: #ffffff;
          }
          .anticon {
            color: #ffffff;
          }
        }
      }
      .title {
        text-align: left;
        font-weight: 700;
        color: #fafafa;
      }

      .description {
        color: #fafafa;
      }
    }
  }

  .faq-content {
    padding: 50px;

    .ant-layout-sider {
      background: transparent;
      .ant-layout-sider-children {
        border-width: 0;
        padding-top: 180px;
        h4 {
          padding: 0 16px;
        }
        .ant-menu {
          border-width: 0;
          background: transparent;
          li {
            background: transparent;
            &.ant-menu-item-selected {
              color: $text-primary-color-active;
              font-weight: bold;
            }
          }

          .ant-menu-item {
            height: auto;
            margin-top: 40px;
            padding: 0 16px;
            font-size: 14px;
            line-height: normal;
            word-wrap: break-word;
            white-space: normal;
            margin-right: 70px;
            font-weight: 600;
          }
        }
      }
    }

    .ant-layout-content {
      .ant-collapse {
        padding: 20px 40px;
        margin: 10px;
        border-radius: 0;
        -webkit-box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 5px 0 15px 0;
        }
        .ant-collapse-item {
          border-bottom-color: $secondary-border;
          .ant-collapse-header {
            font-weight: bold;
            padding: 20px 0;
          }
          &.ant-collapse-item-active {
            .ant-collapse-header {
              color: $text-primary-color-active;
            }
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .ant-layout.banner {
      main {
        padding: 40px 0;
        .title,
        .description {
          text-align: center;
          color: #fff;
        }
        .title {
          font-size: 1.5rem;
        }
        .description {
          margin: 20px 0;
          font-size: 1rem;
        }
      }
    }

    .faq-content {
      .search-form {
        .ant-input {
          border-radius: 4px !important;
          padding-left: 35px;
        }

      }
      .ant-collapse {
        margin: 0px !important;
        padding: 0px !important;

        .ant-collapse-item {
          border: 1px solid #eee;
          margin-bottom: 20px;
        }
        .ant-collapse-header {
          padding: 20px !important;

          label {
            font-size: 1rem !important;
            display: block;
            margin-right: 30px;
          }

          svg {
            font-size: 1.1rem !important;
          }
        }
      }
      .ant-collapse-content-box {
        padding: 0 20px 20px 20px !important;
      }
      padding: 20px;
      .frequently {
        font-family: "Montserrat";
        font-size: 1.1rem;
        text-align: center;
        padding: 5px 0 10px 0;
        color: #000;
      }
    }
    .holds-yt iframe {
      width: 100%;
      height: 100%;
    }
  }

  .helpful-action {
    display: flex;
    align-items: center;

    p {
      margin:0 10px; 
      color: black;
      font-weight: 500;
      font-size: 12px;
    }

    .helpful-buttons {
      .like-btn {
        margin:5px;
        height: 28px;
        width:82px;
        border: 1px solid #7A4521;

        span {
          color: #7A4521;
          font-size: 11px;
        }

      }

      .dislike-btn {
        margin:5px;
        height: 28px;
        width:82px;
        border: 1px solid #7A4521;
        background-color: #7A4521;

        span {
          color:white;
          font-size: 11px;
        }
      }
    }
  }
}

.faq-drawer {
  .ant-drawer-body {
    padding: 0;
  }
  h4 {
    padding: 16px;
    margin: 0;
  }

  .ant-menu {
    border-width: 0;
    background: transparent;
    li {
      background: transparent;
    width: 100%;

      &.ant-menu-item-selected {
        color: $text-primary-color-active;
        font-weight: bold;
      }
    }

    .ant-menu-item {
      font-size: 14px;
      margin-right: 70px;
      font-weight: 600;
    }
  }
}

.faq-survey-container {

  .survey-header {

    h1{
        text-align: left;
        font-size: 24px;
        font-family: Montserrat;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #000000;
        opacity: 1;
      }
  }

  .survey-content {
    padding: 10px 0;

    .action-helpful {
        margin: 32px 0;

        h1{
        text-align: left;
        font-size: 15px;
        font-family: Open Sans;
        font-weight: 600;
        color: #2B2E43;
        opacity: 1;
      }

      .action-helpful-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .like-btn {
          margin:5px;
          height: 42px;
          width:102px;
          border: 1px solid #7A4521;

          span {
            color: #7A4521;
          }

        }

        .dislike-btn {
          margin:5px;
          height: 42px;
          width:102px;
          border: 1px solid #7A4521;
          background-color: #7A4521;

          span {
            color:white;
          }
        }
      }
    }

    .action-is-not-helpful {
      margin: 32px 0;

      h1{
        text-align: left;
        font-size: 15px;
        font-family: Open Sans;
        font-weight: 600;
        color: #2B2E43;
        opacity: 1;
      }

      .action-helpful-radio {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .action-improve {
      margin: 32px 0;

      h1{
        text-align: left;
        font-size: 15px;
        font-family: Open Sans;
        font-weight: 600;
        color: #2B2E43;
        opacity: 1;
      }

      .action-the-urc {
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
          width: 283px;
          // border: 1px solid lightgray;
          padding: 10px;

          h1 {
            color:gray;
            font-size: 13px;
          }
        }
      }
    }

    .action-feedback {

      .submit-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        h1 {
          margin: 5px 20px;
          color: #BF914A;
          font-size: 15px;
        }

         button {
           margin: 5px;
           background-color: #BF914A;
           color: white;
           height: 42px;
           width: 192px;
         }

      }
    }
    
  }
}

