@import "../../assets/styles/variables";

.po-page-header {
  margin-top: 15px;
  padding: 24px;
  background-color: $strip-background;
  font-family: "Open Sans";

  .po-page-sub-header {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    // font-size: 1.06rem;
    z-index: 2;

    div {
      color: #E4B6BA;
      font-size: 0.9rem;
      padding: 0 20px;
    }

    span {
      margin-right: 25px;
      font-family: "Open Sans";
      font-size: 0.9rem;
      color: $text-white-color;
    }

    .order-status-tag {
      white-space: nowrap;
      font-weight: 700;
      font-family: "Open Sans";
      text-align: left;
      padding: 0;

      .active {
        border-radius: 50px;
        padding: 5px 15px;
        min-width: 100px;
        color: #3383FF;
        background-color: #C0D8FF;
        border: 1px solid #629FFF;
        font-size: 0.7rem !important;
      }

      .cancelled {
        border-radius: 50px;
        padding: 5px 15px;
        min-width: 100px;
        color: #a80f1b;
        background-color: #fef3f3;
        border: 1px solid #f5a2a6;
        font-size: 0.7rem !important;
      }

      .invoiced {
        border-radius: 50px;
        padding: 5px 15px;
        min-width: 100px;
        color: #028010;
        background-color: #e6fff1;
        border: 1px solid #78d89f;
        font-size: 0.7rem !important;
      }

      .in-process {
        border-radius: 50px;
        padding: 5px 15px;
        min-width: 100px;
        color: #d66f0e;
        background-color: #ffefe0;
        border: 1px solid #ffc590;
        font-size: 0.7rem !important;

      }
    }
  }
}

.po-page-content {
  padding: 50px;
  font-family: "montserrat";

  // li:nth-child(odd) {
  //   background: #e8e8e8;
  // }

  td {
    width: 400px;
  }

  .po-sub-row {
    background-color: red;
    .ant-collapse > .ant-collapse-item {
      border-bottom: 0 !important;
    }
    .ant-collapse-content {
      border: 0;
      .ant-collapse-content-box {
        padding: 0;
        border-top: 0;
      }
    }

    .ant-list-header {
      background-color: #fafafa;
    }

    .header {
      .ant-collapse-header {
        font-size: 1.3rem;
        font-weight: 500;
        background-color: white;
        font-family: "Open Sans";
        color: black;
        border-radius: 0 !important;
        border: none;
        width: 100%;
        height: 70px;
        margin-top: 36px;
        padding-left: 10px;
        line-height: 45px;

        i svg {
          font-size: 2rem;
        }
      }
    }
    .footer {
      .ant-collapse-header {
        font-size: 16px !important;
        background-color: #e6e6e6;
        font-family: "Open Sans";
        color: black;
        border-radius: 0 !important;
        border: none;
        width: 100%;
        min-height: 70px;
        // padding-left: 70px;
        padding: 20px 20px 0 !important;
        line-height: 20px !important;

        i svg {
          font-size: 2rem;
        }
      }
    }
    .ant-collapse {
      border: 0;
    }
  }

  .overall-total {
    //   .overall-total-content {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     justify-content: space-between;

    //     .overall-total-label {
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       padding: 20 !important;
    //       font-size: 16 !important;
    //       font-weight: bold;
    //     }

    //     .overall-total-summary {
    //       display: flex;

    //       .overall-total-display {
    //         margin: 20 !important;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //       }
    //     }
    //   }

    .ant-collapse-header {
      padding: 0px !important;
      font-size: 10px !important;
      background-color: #4f4f4f !important;
      text-decoration: none !important;
      font-family: "Open Sans";
      color: #ffffff !important;
      border-radius: 0 !important;
      width: 100%;
      height: 50px;
      padding-left: 70px;
      line-height: 8px !important;

      i svg {
        font-size: 2rem;
      }
    }
  }

  .po-row {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-header {
      font-size: 1.75rem;
      background-color: $strip-background;
      font-family: "Open Sans";
      color: $text-white-color;
      border-radius: 0 !important;
      width: 100%;
      height: 100;
      padding-left: 70px;
      line-height: 60px;

      i svg {
        font-size: 2rem;
      }
    }

    // .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    //   overflow: auto !important;

    //   -ms-overflow-style: none;
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }
    // }
  }

  .po-row-breakdown {
    .ant-collapse-item {
      border: 0 !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-header {
      font-size: 1rem;
      background-color: white;
      font-family: "Open Sans";
      color: #930000;
      border-radius: 0 !important;
      width: 100%;
      height: 100;
      padding-left: 70px;
      line-height: 60px;
      text-decoration: underline;

      i svg {
        font-size: 2rem;
      }
    }

    // .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    //   overflow: auto !important;

    //   -ms-overflow-style: none;
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }
    // }
  }

  a {
    color: $text-white-color;
    font-size: 2rem;
  }

  h2 {
    flex: 1;
    color: $text-white-color;
    margin: 0;
    font-size: 1.8rem;
    padding: 0 20px;
  }

  .po-page-filters {
    margin: 20px 0;

    .ant-input-affix-wrapper {
      margin-right: 15px;

      .ant-input,  .ant-input:focus, .ant-input:hover {
          border: 1px solid #B2B2B2;
          box-shadow: none;

      }
      .ant-input-prefix svg {
          color: #A7A7A7;
      }
      .ant-input::placeholder {
          color: #777777;
      }
    }

    .ant-select-selection {
        font-family: "Montserrat";
        border: 1px solid #B2B2B2;

        svg{
            color: #A7A7A7;
        }
    }

    .ant-select-selection__placeholder {
        color: #707070;
    }
  }

  .po-page-table {
    // overflow-x: auto;

    .ant-list-header {
      background-color: #fafafa;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
    }

    .ant-list-footer {
      background-color: #4f4f4f;

      .footer-label {
        display: flex;
        flex-direction: column;

        h1 {
          margin: 0;
          font-size: 16px;
          color: #ffffff;
        }

        span {
          font-size: 10px;
          color: #ffffff;
        }
      }
    }

    .ant-table-column-title {
      font-family: "Open Sans";
      color: #999999;
      font-size: 0.8rem;

      p {
        margin-bottom: 0;
      }
    }
    .ant-table-footer {
      background-color: $strip-background;
      color: $text-white-color;
      .ant-col {
        small {
          margin-right: 0.5rem;
        }
        span {
          font-size: 1rem;
        }
      }
    }
  }
}

.pb-parent-columns {
 .ant-table-row {
   background: #FFFFFF;
 }
 .ant-table-expanded-row td {
   background: #F9F9F9 !important;
 }

 .ant-table-row-expand-icon-cell {
   svg {
     font-size: 26px;
   }
 }

  .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #999999;
  }
  table thead tr th:nth-child(8) {
    text-align: center;
    padding-left: 16px;
  }
}

.mobile {
  .ant-collapse-header {
    font-size: 1.5rem !important;
  }

  .po-page-content {
    padding: 20px;

    .po-page-table {
      .dot {
        justify-content: center;
        text-align: center;
      }
    }

    .po-page-header {
      margin-top: 15px;
      padding: 24px;

      .po-page-sub-header {
        font-size: 1rem;
        span {
          margin-right: 10px;
        }
      }
    }

    // .po-page-filters {
    //   margin: 20px 0;

    //   .ant-input-affix-wrapper {
    //     margin-right: 15px;
    //     margin-bottom: 10px;
    //     width: 100% !important;
    //   }

    //   .ant-col {
    //     width: 100%;
    //   }

    //   .ant-select {
    //     width: 100% !important;
    //     margin: 10px 0;
    //   }
    // }
  }
}

// new stylesheet

.xs-container-breakdown {
  color: #fff;
  position: relative !important;

  .sub-header {
    width: 100%;
    font-family: "Open Sans";
    background-color: #a80f1b;
    padding: 20px;
    position: fixed;
    top: 63px;
    z-index: 4;

    img {
      width: 1.5rem;
    }
    span {
      font-family: "Open Sans";
      font-size: 20px;
      line-height: 27px;
      color: #fff;
      margin: 0px;
      padding: 0px;
    }
  }
  .upper-details {
    background-color: #a80f1b;
    z-index: 2;
    padding: 20px;
    padding-top: 60px;
    > div {
      padding: 5px 0;
    }

    label,
    span {
      display: block;
      text-transform: uppercase;
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
      color: #fff;
    }
    label {
      opacity: 0.69;
      padding: 2px 0;
    }
    span {
      //
    }
  }
}

.tabs {
  width: 100%;
  padding: 0 20px;

  background-color: #fff;
  .ant-tabs {
    .ant-tabs-tab {
      font-size: "Open Sans";
      color: #484848;
      font-size: 14px;
    }

    .ant-tabs-tab-active {
      color: #a80f1b;
    }

    .ant-tabs-ink-bar-animated,
    .ant-tabs-ink-bar {
      border: 1.2px solid #a80f1b !important;
    }
  }

  .ant-tabs-nav,
  .ant-tabs-nav-animated {
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      > div:first-child {
        border-right: 1px solid #eee;
      }
      > div {
        flex: 1;
        margin: 0px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.tabs-scrolled {
  position: fixed !important;
  top: 130px;
  left: 0;
  z-index: 10;
  width: 100%;
}
.sku-main-list,
.breakdown-main-list {
  padding: 20px;
  padding-top: 0px;
  .ant-list-footer {
    padding: 0px;
  }
}
.list-footer {
  background: #4f4f4f;
  font-family: "Open Sans";

  text-transform: uppercase;
  color: #f2f2f2;
  padding: 15px 20px;

  > div {
    padding: 5px 0;
  }
  > div:nth-child(odd) {
    text-align: right;
    font-family: "Montseratt";
    font-size: 0.9rem !important;
  }
  > div:nth-child(even) {
    font-size: 0.7rem !important;
  }
  > div:first-child {
    text-align: left;
    font-family: "Open Sans";
    font-size: 0.8rem !important;
  }
}

.sku-details-list,
.breakdown-details-list {
  padding: 20px !important;
  padding-bottom: 40px !important;
  margin: 20px 0;
  margin-bottom: 0px !important;
  border-radius: 0px;
  border: 1px solid #e0e0e0;
  > div > div {
    padding: 5px 0;
  }
  > div:first-child {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  > div > div:nth-child(odd) {
    text-transform: uppercase;
    font-family: "Open Sans";
    font-size: 12px;
    color: #a7a7a7;
  }
  > div > div:nth-child(even) {
    text-align: right;
    font-family: "Montserrat";
    font-size: 15px;
    color: #404040;
  }

  .xs-activity {
    h4 {
      font-weight: 600;
      color: #383838 !important;
      text-align: right;
      margin-bottom: 0;
      font-family: "Open Sans";
    }
    > div {
      float: right;
    }
  }
}

.search-holder {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 800px) {
  section.purchase-status {
    .ant-layout-footer {
      display: none !important;
    }

    .row-container-break-down {
      padding: 20px;
      padding-top: 0px;

      .search-container {
        padding-bottom: 15px;
        width: 100%; 
      }

      h2 {
        font-family: "Open Sans";
        font-weight: 400;
        color: #000;
      }
      .ant-collapse-header {
        border: 0.5px solid #e0e0e0;
        border-bottom: none;
        border-radius: 0px !important;
        background-color: #fff;
        padding: 30px 20px;
        z-index: 1;
      }

      .ant-collapse-arrow {
        height: 50px !important;
        width: 92% !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
      }
      .ant-collapse-content-box {
        padding: 0px;
      }
      .dot {
        justify-content: flex-end;
      }
      .ant-collapse > .ant-collapse-item {
        border-radius: none;
        /* border-bottom: 1px solid #d9d9d9; */
      }

      .ant-collapse {
        border: none;
      }
    }
  }
}
.activeIndicator {
  position: relative;
  //
}

.activeIndicator:before {
  content: "";
  background: #a40e1a;
  width: 5px;
  height: 63px;
  position: absolute;
  top: -7px;
  left: -20px;
}

.collapse-inner-first {
  label {
    font-family: "Open Sans";
    font-size: 0.8rem;
    color: #a7a7a7;
    text-transform: uppercase;
  }
  span {
    font-family: "Montserrat";
    font-size: 1rem;
    color: #404040;
    text-transform: uppercase;
  }
  > div {
    padding: 5px 0;
    text-align: left;
  }
}

.collapse-icon-holder {
  .subt {
    color: #a40e1a;
    font-size: 2rem;
  }

  .add {
    color: #404040;
    font-size: 1.8rem;
  }
}

.breakdown-details-list {
  background-color: #f9f9f9;
}

.breakdown-main-list {
  padding: 0px;
}

.cb-table p {
  margin: 0;
  padding: 0;
}

.cb-table-nested td:nth-child(1) {
  padding-right: 8px;
}

.cb-table-nested td:nth-child(2) {
  padding-left: 10px;
  padding-right: 10px;
}
.cb-table-nested td:nth-child(3) {
  padding-left: 0px;
}

.cb-table-nested td:nth-child(4) {
  padding-left: 8px;    
  padding-right: 10px;
}

.cb-table-nested td:nth-child(5) {
  padding-left: 0px;
  padding-right: 34px;
}

.cb-table-nested td:nth-child(6) {
  padding-left: 28px;
  padding-right: 0px;
}

.cb-table-nested td:nth-child(7) {
  padding-right: 0px;
  padding-left: 0px;

}

.cb-table-nested td:nth-child(8) {
  padding-right: 8px;
  padding-left: 0px;

}

.cb-table-nested td:nth-child(9) {
  padding-right: 0px;
  padding-left: 0px;

}

.cb-table-nested td:nth-child(10) {
  padding-left: 0px;
  padding-right: 10px;

}

.cb-table-nested td:nth-child(11) {
  padding-left: 10px;
}

.container-break {
  display: none;
}

.xs-activity {
  text-align: center;
  h4 {
    font-family: "Open Sans";
    font-weight: 600;
    color: #383838 !important;
    text-align: center;
    margin-bottom: 0;
  }
  > div {
    display: flex;
    justify-content: center;
  }
}
