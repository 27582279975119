.avatar-uploader img {
  max-height: 1000px;
  background: blue;
  width: 100%;
}

.image-uploader {
  display: flex;
  justify-content: center;
}
.image-uploader .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 100px;
}

.image-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0 !important;
}