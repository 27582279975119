.summary-container {
    padding: 50px;

    .summary-header-first {
      // display: flex;
      align-items: center;
      background: #A40E1A;
      // height: 80px;

      h1{
        padding: 24px;
        margin:0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        color: #FFFFFF;
        // padding:24px
      }

      .summary-header-second {
        width: 100%;
        padding: 24px;

        .total-text {
          display: flex;
          // flex-direction: row;
          // justify-content: center;
          // align-items: center;
          white-space: nowrap;

          h1{
            margin:0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            padding:0;
            opacity: 0.9;

            span{
              margin:10px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 28px;
              line-height: 38px;
              color: #FFFFFF;
            }
          }

        
        }
      }

    }

    .summary-header {
        display: flex;
        align-items: center;
        background: #A40E1A;
        min-height: 140px;
        
        .summary-header-content {
          h1{
            padding: 0 12px 18px;
            margin:0;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            // line-height: 38px;
            color: #FFFFFF;
            // padding:24px
          }

         .summary-header-sub-content {
            display: flex;
            
            div {
              // padding:12px;

              h1{
                margin:0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                // line-height: 38px;
                color: #FFFFFF;
                padding: 0 12px;
                opacity: 0.7;
              }
  
              p {
                margin:0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                // line-height: 38px;
                color: #FFFFFF;
                padding: 0 12px;
                opacity: 0.9;
              }
            }
            
          
         }

        }
      

        a {
          margin-left: 20px;
          font-size: 30px;
          color:white;
        }
    }

    .summary-filter {
        padding: 24px 0 5px;

        .filter-radio {
            padding-bottom: 14px;
            .radio {
              display: inline-block;
              position: relative;
              padding-left: 32px;
              padding-top: 4px;
              margin-bottom: 12px;
              margin-right: 12px;
              cursor: pointer;
              font-size: 14px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
        
            /* Hide the browser's default checkbox */
            .radio input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }
        
            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background-color: transparent;
              border: 1px solid #1F2D3D;
            }
        
            /* On mouse-over, add a grey background color */
            .radio:hover input ~ .checkmark {
              background-color: #ccc;
            }
        
            /* When the checkbox is checked, add a blue background */
            .radio input:checked ~ .checkmark {
              background-color: #1F2D3D;
            }
        
            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
        
            /* Show the checkmark when checked */
            .radio input:checked ~ .checkmark:after {
              display: block;
            }
        
            /* Style the checkmark/indicator */
            .radio .checkmark:after {
              left: 12px;
              top: 7px;
              width: 6px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
      
        }

        .filter-sort-by {
            
        }

        // .extract-btn {
            
        //     span{
        //         padding: 0 24px;
        //     }

        // }

    }

    .summary-table {   
      overflow: auto;
      
      .summary-table-header {

        label {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }

      .summary-table-footer {

        label {
          font-family: Montserrat;
          color:white;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .ant-list-header {
        background-color: #F8F8F8;
      }

      .ant-list-footer {
        background-color: #4F4F4F;
      }
    }

   .table-status{
      width: 100%;
      display: flex;
      justify-content: center;

      .invoiced-status {
        height:35px;
        width:110px;
        border: 1px solid #78D89F;
        background: #E6FFF1;
        border-radius: 20px;
        line-height: 2.3;

        span{
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: #028010;
        }
      }

      .inProcess-status {
        height:35px;
        width:110px;
        border: 1px solid #FFC590;
        background: #FFEFE0;
        border-radius: 20px;
        line-height: 2.3;

        span{
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: #D66F0E;
        }
    }
   }

   .table-status-sm{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    .invoiced-status {
      height:30px;
      width:90px;
      border: 1px solid #78D89F;
      background: #E6FFF1;
      border-radius: 20px;
      line-height: 1.8;

      span{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: #028010;
      }
    }

    .inProcess-status {
      height:30px;
      width:90px;
      border: 1px solid #FFC590;
      background: #FFEFE0;
      border-radius: 20px;
      line-height: 1.8;

      span{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: #D66F0E;
      }
  }
 }

   .pb-parent-columns {
    .ant-table-row {
      background: #FFFFFF;
    }
    .ant-table-expanded-row td {
      background: #F9F9F9 !important;
    }
   
    .ant-table-row-expand-icon-cell {
      svg {
        font-size: 26px;
      }
    }
  }
  .download-option-modal {
    p {
        color: #000000;
        font-size: 16px;
    }

    .ant-checkbox-group {
      margin-left: 10px;
    }
    .ant-btn{
        border-radius: 46px;
        font-weight: 600;
        box-shadow: none;
      }
      .ant-btn-link {
        color: #7A4521;
        padding: 0;
        font-weight: 500;
      }

      .btn-small {
        height: 42px;
        min-width: 150px;
        font-size: 16px;
        line-height: 22px;
      }

      .btn-default {
        color: #848484;
        border: none;
        &:hover {
          color: #848484;
          border: none;
          background: transparent;
        }
        &:disabled, &:disabled:hover {
          background: transparent;
        }
      }

      .btn-primary {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;
        &:hover, &:active, &:focus {
          background-color: #bf914a;
          border-color: #bf914a;
          color: #FFFFFF;
  
        }
        &:disabled, &:disabled:hover {
          background-color: #E5D3B6;
          border-color: #E5D3B6;
          color: #FFFFFF;
        }
      }
    .ant-checkbox-group-item {
        display: block;
        margin: 15px 0px;
    }
}
}

@media only screen and (max-width: 1250px) {
  .summary-container {
    padding: 20px;
  }
  // new
}
.approvals-container {
  position: fixed;
}

.approvals {

  .ant-collapse-content-active {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }
  }

  p {
    font-size: 0.5rem;
  }

  .so-number {
    color: #BFBFBF;
  }

  .buttons {
    .ant-btn {
      button {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  
    .btn-small {
      padding: 0;
      margin: 0;
    }
  
    .btn-secondary {
      padding: 0;
      margin: 0;
    }
  }
}



.responses {
  .approved {
    color: #399049;
  }

  .not-approved {
    color: #e30612;
  }
}