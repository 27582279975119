//Banner MediaQuery (Mobile View 520px)
@media only screen and (max-width: 520px) {
  .about-us-container {
    .about-us-content {
      .about-us-banner {
        .about-us-banner-content {
          h2 {
            font-size: 30px !important;
          }

          h1 {
            font-size: 36px !important;
          }
        }
      }
    }

    .about-us-description-content {
      margin: 5vh !important;
    }
  }
}

.about-us-container {
  background: #FCF9F4;
  font-family: "montserrat";

  .sidebar-container {
    /* width: 326.46px; */
    background: white;

    .segment-title {
      padding: 24px 24px 5px;
      background: #FCF9F4;

      h3 {
        font-weight: bold;
        color: #814F2C;
        padding: 20px;
      }
    }
  }

  .sidebar-content {
    // .ant-menu-item {
    //   padding-left: 0;
    // }

    

    li {
      // background-color: #fff;
      padding: 0 0 0 46px !important;
      height: 100% !important;
    }

    .ant-menu-item-selected {
      background-color: rgba(255, 154, 0, 0.15) !important;
    }

    .sidebar-label {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: normal;
      color: #7a4521;
    }
  }

  .about-us-content {
    background: white;

    .about-us-banner {
      background-image: url('../../assets/images/about-us-banner/about-us-banner.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #A80F1B;
      height: 300px;

      .about-us-banner-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 3vw 5vw;
        height: 100%;

        h2 {
          color: white;
          font-size: 42px;
          font-weight: 200;
          margin-bottom: 0;
        }

        h1 {
          color: white;
          font-size: 60px;
          font-weight: 900;
          // text-shadow: black 1px 0 15px;
        }

        img {
          height: 100%;
          width: 100%;
          margin: 10px;
        }

      }

    }
  }

  .about-us-description-content {
    margin: 10vh;
    line-height: 1.8;

    .mobile-filter-toggle {
      color: red;
    }

    span {
      /* color:white; */
      font-size: 16px !important;
      color:#323232 !important;
      margin: 20px;

      p {
        font-family: "Open Sans";
        text-indent: 50px;
        text-align: justify;
        line-height: 30px;
      }
    }

  }
}