.login-success-modal {
  .ant-modal-header {
    text-align: center;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: 0 5px 1px 0px #FAFAFA;

    .ant-modal-title {
      font-size: 20px;
      font-weight: 800;
      padding: 10px;
    }
  }

  .ant-modal-content {
    border-radius: 30px;
  }
}

.login-container {

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
  }

  .login-form-forgot {
    color: #932222;
    font-weight: 600;
    font-size: 13px;

    &:active {
      color: #932222;
      font-weight: 600;
      font-size: 13px;
    }

    &:hover {
      color: #932222;
      font-weight: 600;
      font-size: 13px;
    }

    #triangle-up {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 20px solid white;
      position: absolute;
      top: -20px;
      right: 15%;
    }
  }

  .login-submit-button {

    margin: 20px 0px;
    background: rgb(191, 145, 74);
    // padding: 25px;
    border: 1px solid rgb(191, 145, 74);
    border-radius: 25px !important;
    line-height: 0px;
    width: 190px;
    height: 40px;
    width: 100%;

    span {
      color: white;
    }
  }

  .login-error-message {
    font-style: italic;
    color: red;
  }

}