.order-summary-dashboard-page {

    .ant-card {
        border-radius: 6px;
    }

    .ant-card-body {
        padding: 0;
    }

    .ant-card-bordered {
        box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.07);
    }

    .ant-card-head {
        border-bottom: none; 
        color: #898989;
        font-weight: 600;
        font-size: 14px;
    }
    
    .ant-card-grid {
        border-top: 1px solid #e8e8e8;
        width: 100%;
        box-shadow: none;
        padding: 14px 24px;

        .ant-row {
            margin: 2px 0px;
        }
        h3 {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #898989;
        }

        p {
            font-weight: 600;
            margin-bottom: 8px;
            font-size: 12px;
            color: #9F9F9F;
        }
        span {
            font-family: "Montserrat";
            font-size: 18px;
            white-space: nowrap;
        }
    }

    .ant-progress-bg {
        height: 12px !important;
    }
    

    .header {
        .dropdowns {
            p {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 10px;
                color: #6A6A6A;
            }
        }
        h3 {
            font-family: "Open Sans";
            font-size: 28px;
        }
        p {
            padding: 0px;
            margin: 0;
            font-size: 16px;
            color: #6A6A6A;

            span {
                // padding-left: 10px;
                color:#404040;
                font-weight: 600;
            }
        }
    }

    .main-card {
        span {
            color: #1F3BB3;
        }
    }

    .percentage-card {
        min-height: 180px;

        h4.ant-typography {
            color: #898989;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 14px;
        }
    }

    .bar-description {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 14px;
        color: #808080;
    }
    .bar-percent {
        font-family: "Montserrat";
        font-size: 18px;
        color: #808080;
    } 
    .header-description {
        font-size: 12px;
        line-height: 16px;
        color: #B7B7B7;
    }

    .delivery {
        .ant-progress-text {
            font-family: Montserrat;
            color: #0785E3;
            font-size: 24px;
        }
    }

    
    .invoiced {
        .ant-progress-text {
            font-family: Montserrat;
            color: #1F3BB3;
            font-size: 24px;
        }
    }
    

}

.po-rate-card {
    min-height: 248px;

    .delayed {
        .ant-progress-inner {
            background-color: #FBDADB;
        }

        .ant-progress-bg {
            background-color: #EA444D;
        }
    }

    .not-delayed {
        .ant-progress-inner {
            background-color: #CDE7F9;
        }

        .ant-progress-bg {
            background-color: #7AC6FF;
        }
    }

    .ant-empty {
        margin-bottom: 40px;
        margin-top: 20px;
    }
    
}

.summary-card {
    min-height: 448px;
}

.top-sku-card {
    min-height: 445px;
   

    .ant-card-head {
        min-height: 38px;
    }

    .ant-card-body {
        height: 370px;
        overflow: auto;
        margin-right: 4px;

        &::-webkit-scrollbar {
            width: 4px;
           
        }
  
        &::-webkit-scrollbar-thumb {
            background: #DFDFDF; 
            border-radius: 6px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #C1C1C1; 
        }
    }

    .ant-empty {
        margin-bottom: 40px;
        margin-top: 110px;
    }

    .sku-description {
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        font-family: 'Open Sans';
        font-weight: normal;
        span {
            color: #202020;
        }
        p {
            color: #9F9F9F;
            margin: 0;
        }
    }
    .sku-percent {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: Montserrat;
        color: #0785E3;
        font-size: 18px;
    }

    .ant-progress-bg {
        height: 6px !important;
    }

    .ant-progress-inner {
        background-color: #CDE7F9;
    }
}


.dropdowns-mobile {
    p {
        margin-bottom: 10px;
        font-size: 12px;
        color: #6A6A6A;
    }
    .ant-col {
        margin: 10px 0;
    }
}

// From W3school

.radio {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  padding-top: 4px;
  margin-bottom: 12px;
  margin-right: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #1F2D3D;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio input:checked ~ .checkmark {
  background-color: #1F2D3D;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio .checkmark:after {
  left: 12px;
  top: 7px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mobile {
    .percentage-card {
        height: auto;
    }
    .summary-card {
        height: auto;
    }
    
    .po-rate-card {
        height: auto;
    }

    .top-sku-card {
        height: auto;
    }

    .ant-empty {
        margin-bottom: 30px;
        margin-top: 10px;
    }
}


.download-option-modal {
    p {
        color: #000000;
        font-size: 16px;
    }

    .ant-checkbox-group {
      margin-left: 10px;
    }
    .ant-btn{
        border-radius: 46px;
        font-weight: 600;
        box-shadow: none;
      }
      .ant-btn-link {
        color: #7A4521;
        padding: 0;
        font-weight: 500;
      }

      .btn-small {
        height: 42px;
        min-width: 150px;
        font-size: 16px;
        line-height: 22px;
      }

      .btn-default {
        color: #848484;
        border: none;
        &:hover {
          color: #848484;
          border: none;
          background: transparent;
        }
        &:disabled, &:disabled:hover {
          background: transparent;
        }
      }

      .btn-primary {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;
        &:hover, &:active, &:focus {
          background-color: #bf914a;
          border-color: #bf914a;
          color: #FFFFFF;
  
        }
        &:disabled, &:disabled:hover {
          background-color: #E5D3B6;
          border-color: #E5D3B6;
          color: #FFFFFF;
        }
      }
    .ant-checkbox-group-item {
        display: block;
        margin: 15px 0px;
    }
}

@media all and (max-width: 480px)  {
    .order-summary-dashboard-page {
        .ant-card-grid {
            width: 100%;
        }
    }
}
