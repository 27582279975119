.ant-layout-footer {
    background-color: #2c2c2c;
    padding-top: 50px;
    padding-bottom: 50px;

    .logo {
        width: 120px;
        height: 38px;
        background-image: url('../../../assets/images/logo-white.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
    }

    h5 {
        color: #CCCCCC;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    p {
        color: #CCCCCC;
        font-size: 0.9rem;
        line-height: 1.5rem;
    }

    .ant-list {
        .ant-list-item {
            border-width: 0;
            padding: 0.2rem 0;

            a {
                color: #CCCCCC;

                &:hover {
                    color: #FFFFFF;
                }
            }
        }
    }

    .icons-list {
        a {
            font-size: 2rem;
            color: #CCCCCC;
            display: inline-block;
            margin: 0 0.3rem;

            &:hover {
                color: #FFFFFF;
            }
        }
    }

    &.mobile {
        padding: 24px 15px;

        .ant-row-flex {
            .ant-col {
                padding: 0 !important;
            }

            &>div:first-child {
                margin-bottom: 24px;

                .logo {
                    margin: 0 auto;
                }
            }
        }

        .ant-menu {
            background: transparent;
            border: none;
            border-bottom: 1px solid #666666;

            &:last-child {
                border-width: 0;
            }

            .ant-menu-submenu {
                text-transform: uppercase;

                .ant-menu-submenu-title {
                    color: #FFFFFF;
                    font-weight: bold;
                    height: 50px;
                    line-height: 50px;
                    margin: 0;

                    .ant-menu-submenu-arrow {

                        &:before,
                        &:after {
                            color: #FFFFFF;
                            background: #FFFFFF;
                        }
                    }

                    &:active {
                        background: transparent;
                    }
                }
            }

            .ant-menu-item {
                border: none;

                a {
                    color: #FFFFFF;
                }

                &.ant-menu-item-active {
                    background: rgba(255, 255, 255, 0.1);
                }
            }

            &.address {
                .ant-menu-item {
                    height: auto;
                    line-height: auto;

                    p {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}