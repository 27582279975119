@import "../../assets/styles/variables";

.order-status-content {
  padding: 50px;
  font-family: "montserrat";

  li:nth-child(odd) {
    background: #e8e8e8;
  }

  // .ant-table-thead > tr > th,
  // .ant-table-tbody > tr > td {
  //   padding: 16px 14px;
  // }

  .summary-header-first {
    // display: flex;
    align-items: center;
    background: #A40E1A;
    // height: 80px;

    h1{
      padding: 24px;
      margin:0;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 38px;
      color: #FFFFFF;
      // padding:24px
    }

    .summary-header-second {
      width: 100%;
      padding: 24px;

      .total-text {
        display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        white-space: nowrap;

        h1{
          margin:0;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          padding:0;
          opacity: 0.9;

          span{
            margin:10px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 38px;
            color: #FFFFFF;
          }
        }

      
      }
    }

  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 10px;
    overflow-wrap: break-word;
  }

  .column-hide{
    display: none;
  }

  .column-show{
    display: inline;
  }

  .order-status-row {
    .ant-table {
      overflow: auto;
      height: 50vh;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .ant-table-empty {
      height: 40vh;
    }

    .ant-table-placeholder {
      border-bottom: none;
    }

    div.ant-table th {
      position: sticky !important;
      top: 0;
      z-index: 1;
    }
  }

  .search-date-container {
    margin-right: 20px;
  }

  a {
    color: $strip-background;
  }

  h2 {
    flex: 1;
    color: $text-white-color;
    margin: 0;
  }

  .order-status-header {
    padding: 24px;
    background-color: $strip-background;
    color: $text-white-color;
    h1 {
      color: $text-white-color;
      font-family: "Open Sans";
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 38px;
    }
    span,
    label {
      font-family: "Montserrat";
      color: #f2f2f2;
    }
    label {
      font-size: 0.8rem;
    }
    span {
      padding-left: 10px;
      font-size: 1.4rem;
    }
    .total-row {
      > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: baseline;
      }
    }
  }

  .order-status-filters {
    margin: 20px 0;

    .ant-input-affix-wrapper {
      margin-right: 15px;
    }
  }

  .order-status-table {
    // zoom: 90%;
    font-family: "Open Sans";
    overflow-x: auto;

    .ant-table-row {
      font-family: "Montserrat";
      color: #484848;
    }
    .ant-table-column-title {
      color: #999999;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 0.71rem;
      // line-height: 16px;

      p {
        margin-bottom: 0;
      }
    }
    .ant-table-tbody {
      tr {
        cursor: pointer;
      }
      td {
        font-size: 0.75rem;
      }
      .order-status-tag {
        white-space: nowrap;
        font-weight: 700;
        font-family: "Open Sans";

        .active {
          border-radius: 50px;
          padding: 5px 15px;
          min-width: 100px;
          color: #d66f0e;
          background-color: #ffefe0;
          border: 1px solid #ffc590;
          font-size: 0.7rem !important;
          }

        .cancelled {
          border-radius: 50px;
          padding: 5px 15px;
          min-width: 100px;
          color: #a80f1b;
          background-color: #fef3f3;
          border: 1px solid #f5a2a6;
          font-size: 0.7rem !important;
        }

        .invoiced {
          border-radius: 50px;
         padding: 5px 15px;
          min-width: 100px;
          color: #028010;
          background-color: #e6fff1;
          border: 1px solid #78d89f;
          font-size: 0.7rem !important;
        }

        .in-process {
          border-radius: 50px;
          padding: 5px 15px;
          min-width: 100px;
          color: #d66f0e;
          background-color: #ffefe0;
          border: 1px solid #ffc590;
          font-size: 0.7rem !important;
        }
      }
    }
  }
}

.mobile {
  .order-status-content {
    padding: 0px;

    li:nth-child(odd) {
      background: #fff;
    }

    h2 {
      font-size: 1.8rem;
    }

    .order-status-filters {
      > div {
        position: relative;
        .filter {
          position: absolute;
          top: 0.5px;
          right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            &:hover {
              opacity: 0.5;
            }
          }
          .ant-select-selection {
            height: 33px;
          }
        }
      }
    }
  }

  .order-status-row {
    > div:first-child {
      position: sticky;
      top: 64px;
      z-index: 10;
    }
    > div:nth-child(2) {
      position: sticky;
      top: 170px;
      z-index: 10;
      background-color: #fff;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      padding: 0px 0px;
    }
  }

  .order-status-header {
    padding: 15px 24px;
    color: $text-white-color;

    h1 {
      font-size: 1.5rem;
    }
    label {
      font-size: 0.8rem;
      font-weight: 300;
      font-family: "Open Sans";
    }
    span {
      padding: 0px;
    }
    .total-row {
      > div {
        text-align: right;
        > label {
          order: 2;
        }
        > span {
          order: 1;
        }
      }
    }
  }
  .ant-list {
    border-radius: 0px;
    border: none;
    .ant-list-item {
      padding: 0px;
      margin-bottom: 20px;
    }
  }
  .ant-select-selection {
    border-top: none !important;
    border-bottom: none !important;
  }
  .ant-select-selection-selected-value {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .--select {
    position: fixed;
    top: 515px !important;
    left: 0px !important;
    width: 100% !important;
    height: 200px !important;
    border-radius: 0px !important;
    background-color: #fff !important;
    z-index: 4 !important;

    .ant-select-dropdown-menu-item,
    .ant-select-dropdown-menu-item.all {
      font-family: "Open Sans";
      font-size: 1rem;
      font-weight: 100;
      color: #404040;
      padding: 10px 20px;
      text-transform: capitalize;
    }

    .ant-select-dropdown-menu-item-selected {
      background-color: #fff;
    }
    .ant-select-dropdown-menu-item-active {
      font-weight: 400;
      &:before {
        content: "";
        position: absolute;
        right: 25px;
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        background: center / cover no-repeat url("../../assets/icons/tick.svg");
      }
    }

    > div {
      height: 200px !important;
      z-index: 1 !important;
      position: relative !important;
    }
    &:before,
    &:after {
      position: absolute;
      left: 0px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      font-family: "Open Sans";
      font-size: 0.9rem;
      font-weight: 600;
      color: #404040;
      background-color: #fff;
    }

    &:before {
      content: "Sort by";
      top: -48px;
      padding-left: 20px;
      justify-content: start;
      box-shadow: 0 -250px 20px 200px rgba(0, 0, 0, 0.2);
    }

    &:after {
      content: "CANCEL";
      top: 200px;
      justify-content: center;
    }
  }
}
.order-status-xs-content {
  width: 100%;
  border: 1px solid #d8d8d8;
  padding: 20px;

  > div {
    padding: 7px 0;

    label {
      text-transform: uppercase;
      font-family: "Open Sans";
      color: #a7a7a7;
      font-size: 12px;
    }
    a {
      color: #0785e3;
      font-size: 15px;
      margin: 0px;
      padding: 0px;
    }
    .create-at,
    .order-num,
    .customer-po-num {
      font-family: "Montserrat";
      font-size: 15px;
    }
    .customer-po-num {
      color: #0785e3;
    }
    .invoiced,
    .in-process,
    .cancelled {
      font-family: "Open Sans";
      font-size: 11px;
      font-weight: 700;
      border-radius: 60px;
      padding: 4px 15px;
      display: inline-block;
    }
    .invoiced {
      color: #028010;
      background-color: #e6fff1;
      border: 1px solid #78d89f;
    }
    .in-process {
      color: #d66f0e;
      background-color: #ffefe0;
      border: 1px solid #ffc590;
    }
    .cancelled {
      color: #a80f1b;
      background-color: #fef3f3;
      border: 1px solid #ffbec2;
    }

    .target-etd {
      color: #ef9643;
      font-family: "Montserrat";
      font-size: 15px;
      font-weight: 600;
    }
    span.theme-color {
      color: #404040;
    }
    span.bolder {
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.feedback-modal {
  font-family: "Open Sans";

  .ant-modal-close-x {
    color: $text-white-color;
    font-size: 0.9rem;
  }

  .ant-modal-header {
    background-color: $strip-background;

    span {
      color: $text-white-color;
      font-size: 1.2rem;
    }
  }

  h3 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .feedback-rate {
    min-height: 60px;

    .ant-col {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #b7eb8f;
        border-color: #73d13d;
      }

      &.active {
        border: 1px solid #73d13d;
        background-color: #b7eb8f;
      }

      img {
        width: 55px;
        height: auto;
      }
    }
  }

  .feedback-question {
    h3 {
      font-size: 0.9rem;
      margin-top: 20px;
      margin-bottom: 0px;
    }

    .inputStyle {
      border: 0;
      background: transparent;
      border-bottom: 2px solid #f0e6d8;
      width: 100%;
      box-shadow: 0 2px 2px -2px #f0e6d8;
      padding: 10px 0;
    }
  }

  .feedback-button {
    margin-top: 15px;
  }
}

// table
// .order-status-table table thead tr th:nth-child(1)
// .ant-table-column-title,
// .order-status-table table thead tr th:nth-child(3)
// .ant-table-column-title,.order-status-table table thead tr th:nth-child(7)
// .ant-table-column-title
//    {
//      white-space: nowrap;
//    }
.order-status-table table thead tr th:nth-child(3),
.order-status-table table thead tr th:nth-child(4) {
  min-width: 160px;
}

.order-status-table table thead tr th:nth-child(6),
.order-status-table table thead tr th:nth-child(8) {
  min-width: 100px;
}

.order-status-edit-icon {
  width: 15px;
}

.recent-order-select {
  width: 200px !important;
}

.approvals-container {
  display:flex;
  
  color: #7A4521;
  width: 40%;
  background-color: #FFEFE0;
  border: 1px solid #F5DEBB;
  display: flex;
  align-self: center;
  position: absolute;
  bottom: 15%;

  .approvals-description {
    width: 100%;
    font-family: "Open Sans";
    font-size: 0.8rem;
    padding: 20px 50px 20px 40px;

    span {
      font-weight: 700;
    }

    p {
      margin: 0;
    }
  }

  .buttons-column {
    padding: 20px 0 20px 0;

    .ant-btn {
      padding: 0;
    }

    .btn-secondary {
      border: 1px solid #7a4521;
      background-color: transparent;
      color: #7A4521;
      font-size: 0.75rem;
      padding: 0;
    }

    .btn-default {
      background-color: transparent;
      color: #7A4521;
      font-size: 0.75rem;
      padding: 0;
    }
  }
}

.approvals-modal {
  .ant-modal-title {
    font-size: 1.3rem;
    padding: 10px;
  }
  .ant-collapse-header {
    font-size: 1.3rem;
    font-weight: 500;
    padding-bottom: 50px;
  }
}

.approvals-accordion {
  .ant-collapse-content-box {
    overflow-y: scroll;
    height: 300px;

    p {
      font-family: "Open Sans";
      font-weight: 500;
      font-size: 1.2rem;
      padding-left: 30px;
    }

    .date {
      padding-left: 30px;
      color: #bfbfbf;
    }

    .btn-secondary {
      border: 1px solid #7a4521;
      background-color: transparent;
      color: #7a4521;
    }

    .approved {
      font-size: 1.3rem;
      font-weight: 700;
    }

    .not-approved {
      font-size: 1.3rem;
      font-weight: 700;
    }

    .buttons {
      display: flex;
      align-self: center;
      justify-content: center;
    }

    .responses {
      display: flex;
      align-self: center;
      justify-content: center;
    }
  }
}

.approvals-widget {
  background-color: #ffefe0;
  border: 1px solid #f5debb;
  border-radius: 50%;
  padding: 15px;
  position: fixed;
  top: 90vh;
  left: 90vw;
  z-index: 99;
  transition: display 0.3s ease-in;
}

.ant-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      border-right-color: #f5a2a6;
      border-bottom-color: #f5a2a6;
    }
  }

  .ant-popover-inner {
    background-color: #FFD8D8;
    border: 1px solid #f5a2a6;
    border-radius: 10px;
    .ant-popover-inner-content {
      p {
        color: #a80f1b;
        padding: auto;
        margin: auto;
      }
    }
  }
}

.approvals-widget:hover {
  cursor: pointer;
}

.ant-divider-horizontal {
  margin: 0 0 20px 0;
}

.status-hover {
  background-color: #FFD8D8;
  color: #881A1A;
}


@import url("https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800");
