@media only screen and (max-width: 600px) {
  .contact-container {
    padding: 4vh !important;
  }
}

//New Code CSS
.contact-us-container {
  font-family: Montserrat;

  .contact-us-banner {
    height:30vh;
    background-color:#840404;
    background-repeat: no-repeat;
    background-image: url('../../assets/images/banners/contact-us-banner.png');

    .contact-us-header-label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      h1{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 37px;
        color:#FFFFFF;
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
      }
    }
  }

  .contact-us-cards {
    margin-top:-7vw;

    .contact-us-content{
      margin: 5vw 10vw;

      .ant-card {
        // padding:2vw;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.07);
      }

      .ant-col .ant-form-item-label{
        label{
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #7F7F7F;
        }
      }

      .contact-form-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
      }

      .input-line{
        border:0;
        border-bottom: 2px solid #DFC8A5;
        opacity: 0.5;

        &:active{
          outline:0;
          box-shadow: none;
        }

        &:focus{
          outline:0;
          box-shadow: none;
        }
      }

      .ant-select {
        border-bottom: 0;
        .ant-select-selection {
          border-top: none !important;
          border-bottom: none !important;
          border: 0;
          border-bottom: 2px solid #DFC8A5 !important;
          opacity: 0.5;
  
          &:active{
            outline:0;
            box-shadow: none;
          }
  
          &:focus{
            outline:0;
            box-shadow: none;
          }
        }
      }

      .contact-information-container{
        .information-title{
          margin-bottom: 10px ;
          h1{
            font-weight: 500;
            font-size: 24px;
            line-height: 33px;
            color: #000000;
          }
        }
        .information-address{
          margin:10px 0;
          h1{
            font-weight: bold;
            font-size: 15px;
            line-height: 25px;
            color: #606060;
          }
          p{
            font-size: 14px;
            line-height: 25px;
            color: #808080;
          }
        }
        .information-email{
          margin:10px 0;
            h1{
            font-weight: bold;
            font-size: 15px;
            line-height: 25px;
            color: #606060;
          }
          p{
            font-size: 14px;
            line-height: 25px;
            color: #808080;
            overflow-wrap: break-word;
          }
        }
      }

      .contact-question{
        .question-content{
          margin:10px 0;
          h1{
            font-weight: 500;
            font-size: 24px;
            line-height: 25px;
            color:black;
          }
          p{
            font-size: 14px;
            line-height: 25px;
            color: #808080;
          }
        }
        .faq-button {
          display: flex;
          justify-content: flex-end;

          a{
            height:30px;
            width:140px;
            opacity: 0.8;
            border: 1px solid #7A4521;
            border-radius: 46px;
            color: #7A4521;
            line-height: 2;
            text-align: center;
          }
        }
      }


    }

    .send-form-button{
      display: flex;
      justify-content: flex-end;

       .btn-primary{
        background: #BF914A;
        border-radius: 46px;
        height: 38px;
      }

      .btn-primary:disabled {
        background-color: lightgray !important;
        color:white !important;
      }
    }
  }
}


 